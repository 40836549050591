import * as yup from 'yup';

import { ERRORS_TEXTS } from '../../../const/validation';

export const schema = yup.object({
  title: yup.string().required(ERRORS_TEXTS.required),
  seoTitle: yup.string().required(ERRORS_TEXTS.required),
  seoDescription: yup.string().required(ERRORS_TEXTS.required),
  date: yup.date().required(ERRORS_TEXTS.required),
  anons: yup.string().required(ERRORS_TEXTS.required),
  description: yup.string().required(ERRORS_TEXTS.required),
  friendlyUrl: yup.string().required(ERRORS_TEXTS.required),
  subscription: yup.boolean().required(ERRORS_TEXTS.required),
  strategies: yup.array().of(yup.string().required(ERRORS_TEXTS.required)).required(ERRORS_TEXTS.required).default([]),
  categories: yup
    .array()
    .of(yup.string().required(ERRORS_TEXTS.required))
    .required(ERRORS_TEXTS.required)
    .min(1, ERRORS_TEXTS.minItems),
  showStrategies: yup.array().of(yup.string().required(ERRORS_TEXTS.required)).required(ERRORS_TEXTS.required),
  author: yup.string().nullable().default(null),
  picture: yup.string().nullable().default(null),
  smallPicture: yup.string().nullable().default(null),
  rightTitle: yup.string().nullable().default(null),
});
