import { UserRoles } from '../types/userRoles';

type Permissions = {
  [key: string]: UserRoles[];
};

export enum UserAction {
  strategy = 'strategy',
  strategyAdd = 'strategy.add',
  strategyPositionRemove = 'strategy.position.remove',
  strategyPositionChange = 'strategy.position.change',
  strategyPositionCurrentShare = 'strategy.position.current-share',
  statistic = 'statistic',
  statisticMoney = 'statistic.money',
  statisticClients = 'statistic.clients',
  statisticErrors = 'statistic.errors',
  schedule = 'schedule',
  scheduleEdit = 'schedule.edit',
  instruction = 'instruction',
  analytic = 'analytic',
  strategyParams = 'strategy-params',
  statisticClientDetails = 'statistic.client.details',
  statisticClientDetailsPersonal = 'statistic.client.details.personal',
  statisticClientDetailsDetach = 'statistic.client.details.detach',
  statisticClientDetailsDev = 'statistic.client.details.dev',
  statisticProblemClients = 'statistic.problems',
  statisticClientInfo = 'statistic.client.info',
  administration = 'administration',
  administrationClients = 'administration.clients',
  administrationUsers = 'administration.users',
  administrationStrategies = 'administration.strategies',
  administrationStrategiesAction = 'administration.strategies.action',
  administrationInvestbox = 'administration.investboxes',
  administrationInvestboxAction = 'administration.investbox.action',
  administrationManagement = 'administration.management',
  administrationDeveloper = 'administration.developer',
  administrationServices = 'administration.services',
  administrationCb = 'administration.cb',
  administrationFaq = 'administration.faq',
  banners = 'banners',
  sandbox = 'sandbox',
  sandboxTrackAdd = 'sandbox.track.add',
  sandboxTrackRemove = 'sandbox.track.remove',
  sandboxModerate = 'sandbox.moderate',
  sandboxModerateAccept = 'sandbox.moderate.accept',
  sandboxModerateCancel = 'sandbox.moderate.cancel',
  sandboxReturn = 'sandbox.return',
  audit = 'audit',
  // sandboxApprove = 'sandbox.approve',
}

export const permissions: Permissions = {
  strategy: [
    UserRoles.Administrator,
    UserRoles.Manager,
    UserRoles.RiskManager,
    UserRoles.Expert,
    UserRoles.Support,
    UserRoles.DigitalExpert,
    UserRoles.ContentManager,
    UserRoles.Developer,
  ],
  'strategy.add': [UserRoles.Administrator, UserRoles.Manager, UserRoles.Developer],
  'strategy.position.remove': [UserRoles.Administrator, UserRoles.Manager],
  'strategy.position.change': [UserRoles.Administrator, UserRoles.Manager],
  'strategy.position.current-share': [UserRoles.Administrator, UserRoles.Manager],
  statistic: [UserRoles.Administrator, UserRoles.Support, UserRoles.DigitalExpert, UserRoles.Developer],
  'statistic.money': [UserRoles.Administrator, UserRoles.Support, UserRoles.DigitalExpert, UserRoles.Developer],
  'statistic.clients': [UserRoles.Administrator, UserRoles.Support, UserRoles.DigitalExpert, UserRoles.Developer],
  'statistic.problems': [UserRoles.Administrator, UserRoles.Support, UserRoles.DigitalExpert, UserRoles.Developer],
  'statistic.client.details': [
    UserRoles.Administrator,
    UserRoles.Support,
    UserRoles.DigitalExpert,
    UserRoles.Developer,
  ],
  'statistic.client.details.personal': [UserRoles.Administrator, UserRoles.DigitalExpert, UserRoles.Support],
  'statistic.client.details.detach': [UserRoles.Administrator],
  'statistic.client.info': [UserRoles.Administrator, UserRoles.Support, UserRoles.DigitalExpert, UserRoles.Developer],
  'statistic.errors': [UserRoles.Administrator, UserRoles.Support, UserRoles.DigitalExpert, UserRoles.Developer],
  schedule: [
    UserRoles.Administrator,
    UserRoles.Manager,
    UserRoles.RiskManager,
    UserRoles.Expert,
    UserRoles.Support,
    UserRoles.DigitalExpert,
    UserRoles.Developer,
  ],
  'schedule.edit': [UserRoles.Administrator, UserRoles.Developer],
  instruction: [UserRoles.Administrator, UserRoles.Manager, UserRoles.RiskManager],
  analytic: [UserRoles.Administrator, UserRoles.ContentManager, UserRoles.DigitalExpert],
  'strategy-params': [
    UserRoles.Administrator,
    UserRoles.RiskManager,
    UserRoles.Expert,
    UserRoles.Support,
    UserRoles.DigitalExpert,
    UserRoles.Developer,
  ],
  portfolio: [UserRoles.Administrator, UserRoles.Expert, UserRoles.DigitalExpert, UserRoles.Developer],
  'portfolio.add': [UserRoles.Administrator, UserRoles.DigitalExpert],
  'portfolio.edit': [UserRoles.Administrator, UserRoles.DigitalExpert],
  'portfolio.remove': [UserRoles.Administrator, UserRoles.DigitalExpert],
  'portfolio.details.view': [UserRoles.Expert],
  'portfolio.statistic': [UserRoles.Administrator, UserRoles.DigitalExpert],
  administration: [UserRoles.Administrator, UserRoles.Developer],
  'administration.users': [UserRoles.Administrator],
  'administration.clients': [UserRoles.Administrator, UserRoles.Developer],
  'administration.strategies': [UserRoles.Administrator, UserRoles.Developer],
  'administration.strategies.action': [UserRoles.Administrator, UserRoles.Developer],
  'administration.investboxes': [UserRoles.Administrator, UserRoles.Developer],
  'administration.investbox.action': [UserRoles.Administrator, UserRoles.Developer],
  'administration.management': [UserRoles.Administrator, UserRoles.Developer],
  'administration.developer': [UserRoles.Developer],
  'administration.services': [UserRoles.Administrator, UserRoles.Developer],
  'administration.cb': [UserRoles.Administrator, UserRoles.Developer],
  'administration.faq': [UserRoles.Administrator, UserRoles.Support, UserRoles.DigitalExpert, UserRoles.Developer],
  banners: [UserRoles.Administrator],
  sandbox: [UserRoles.Administrator, UserRoles.Manager, UserRoles.RiskManager, UserRoles.Developer],
  'sandbox.track.add': [UserRoles.Administrator, UserRoles.Manager, UserRoles.Developer],
  'sandbox.track.remove': [UserRoles.Administrator, UserRoles.Manager, UserRoles.Developer],
  'sandbox.moderate': [UserRoles.Administrator, UserRoles.Manager, UserRoles.Developer],
  'sandbox.moderate.accept': [UserRoles.Administrator, UserRoles.RiskManager, UserRoles.Developer],
  'sandbox.moderate.cancel': [UserRoles.Administrator, UserRoles.RiskManager, UserRoles.Developer],
  'sandbox.return': [UserRoles.Administrator, UserRoles.Developer],
  // 'sandbox.approve': [UserRoles.Administrator]
  audit: [UserRoles.Administrator, UserRoles.Auditor],
};

type IPermissions = (role: UserRoles, action: UserAction) => boolean;

export const checkPermissions: IPermissions = (role, action) => {
  return permissions[action].includes(role);
};
