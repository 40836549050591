import React, { useState, useMemo, useCallback } from 'react';

import { Select, MenuItem, FormControl, InputLabel, Checkbox, ListItemText, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

import { useFormContext } from '../FormProvider';

interface Props {
  name: string;
  label: string;
  options: { value: string; label: string }[];
  fullWidth?: boolean;
  multiple?: boolean;
  margin?: 'none' | 'dense' | 'normal';
}

export const ControlledSelectField: React.FC<Props> = ({
  name,
  label,
  options,
  multiple = false,
  fullWidth = true,
  margin = 'normal',
}) => {
  const { control, errors } = useFormContext();
  const [search, setSearch] = useState('');

  const filteredOptions = useMemo(
    () => (search ? options.filter((option) => option.label.toLowerCase().includes(search.toLowerCase())) : options),
    [search, options],
  );

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }, []);

  const handleClose = () => {
    setSearch('');
  };

  return (
    <FormControl fullWidth={fullWidth} margin={margin}>
      <InputLabel
        id={`${name}-label`}
        sx={{
          '&.Mui-focused': {
            background: (theme) => theme.palette.background.paper,
            padding: '0 8px',
          },
        }}
      >
        {label}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            labelId={`${name}-label`}
            MenuProps={{
              slotProps: {
                paper: {
                  sx: {
                    maxHeight: 200,
                    overflow: 'auto',
                  },
                },
              },
            }}
            error={!!errors?.[name]}
            multiple={multiple}
            onClose={handleClose}
            renderValue={(selected) =>
              multiple
                ? selected.map((value: string) => options.find((opt) => opt.value === value)?.label).join(', ')
                : options.find((opt) => opt.value === selected)?.label || ''
            }
          >
            <TextField
              fullWidth
              size="small"
              placeholder="Поиск..."
              value={search}
              onChange={handleSearch}
              onKeyDown={(event) => {
                event.stopPropagation();
              }}
              onKeyUp={(event) => {
                event.stopPropagation();
              }}
              sx={{ padding: '8px' }}
            />
            {filteredOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {multiple && <Checkbox checked={field.value?.includes(option.value)} />}
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {errors?.[name] && <p style={{ color: 'red' }}>{errors[name]?.message}</p>}
    </FormControl>
  );
};
