export const getCorrectServiceType = (value: string) => {
  if (value === 'Накопилка' || value === 'НК') {
    return 'Накопилка';
  }

  if (value === 'Стратегия АС' || value === 'АС') {
    return 'Автоследование';
  }

  if (value === 'Стратегия АК' || value === 'АК') {
    return 'Автоконсультирование';
  }

  return value;
};

export const getShortServiceType = (value: string): number | string => {
  if (value === 'Накопилка') {
    return 3;
  }

  if (value === 'Автоследование') {
    return 1;
  }

  if (value === 'Автоконсультирование') {
    return 2;
  }

  return value;
};

export const getCorrectCurrencyCod = (value: string): string => {
  if (value === 'RUB') {
    return 'SUR';
  }

  return value;
};

export function onlyUnique(value: any, index: number, array: any) {
  return array.indexOf(value) === index;
}

const parseNumber = (value: unknown): number => {
  if (typeof value === 'string') {
    const cleanedValue = value.replace(/[\s,]+/g, '');
    const parsedValue = parseFloat(cleanedValue);
    return isNaN(parsedValue) ? NaN : parsedValue;
  }
  return typeof value === 'number' ? value : NaN;
};

const parseDate = (value: unknown): Date | null => {
  if (typeof value === 'string') {
    const date = new Date(value);
    return isNaN(date.getTime()) ? null : date;
  }
  return value instanceof Date ? value : null;
};

const isNumber = (value: unknown): boolean => !isNaN(parseNumber(value));
const isDate = (value: unknown): boolean => parseDate(value) !== null;

export function sortColumnFn<T>(row1: T, row2: T, prop: keyof T): number {
  const first = row1[prop];
  const second = row2[prop];

  if (isDate(first) && isDate(second)) {
    const date1 = parseDate(first);
    const date2 = parseDate(second);
    return date1!.getTime() - date2!.getTime();
  }

  if (isNumber(first) && isNumber(second)) {
    const num1 = parseNumber(first);
    const num2 = parseNumber(second);
    return num1 - num2;
  }

  const str1 = String(first || '').toLowerCase();
  const str2 = String(second || '').toLowerCase();

  if (str1 > str2) return 1;
  if (str1 < str2) return -1;

  return 0;
}

const convertToNumberOrString = (val: unknown): string | number => {
  if (typeof val === 'string') {
    const currency = getCorrectCurrencyCod(val);
    const shortServiceType = getShortServiceType(currency);
    const parsed = Number(shortServiceType);
    return isNaN(parsed) ? shortServiceType : parsed;
  }

  if (typeof val === 'number') {
    return val;
  }

  return String(val);
};

export const getFilterMatches = (value: unknown): (string | number)[] => {
  if (Array.isArray(value)) {
    return value.map(convertToNumberOrString);
  }

  return [convertToNumberOrString(value)];
};
