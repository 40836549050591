import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export interface Props {
  title: string;
  description?: string;
  error?: string;
  isLoading?: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export function ConfirmationDialog({ title, description, error, isLoading, onConfirm, onClose }: Props) {
  return (
    <Dialog maxWidth="xs" open>
      <DialogTitle align="center">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {description}
        <br />
        {error && <p className="error-message">{error}</p>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading} startIcon={isLoading && <CircularProgress size={20} />}>
          Отмена
        </Button>
        <Button onClick={onConfirm} disabled={isLoading} startIcon={isLoading && <CircularProgress size={20} />}>
          Ок
        </Button>
      </DialogActions>
    </Dialog>
  );
}
