import { useCallback, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useStore } from 'effector-react';
import { useForm } from 'react-hook-form';

import { FilePath } from './FilePath';
import { useAuthors, useCategories, useUpdateAnalytic } from './queries';
import { schema } from './schema';
import { StrategiesCheckboxGroup } from './StrategiesCheckboxGroup';
import { ControlledCheckboxField } from '../../../components/FormControlsV2/ControlledCheckboxField';
import { ControlledDateTimeField } from '../../../components/FormControlsV2/ControlledDateTimeField';
import { ControlledFileField } from '../../../components/FormControlsV2/ControlledFileField';
import { ControlledSelectField } from '../../../components/FormControlsV2/ControlledSelectField';
import { ControlledTextEditorField } from '../../../components/FormControlsV2/ControlledTextEditorField';
import { ControlledTextField } from '../../../components/FormControlsV2/ControlledTextField';
import { FormProvider } from '../../../components/FormControlsV2/FormProvider';
import { StrategyDictionary$ } from '../../../effector/strategyDictionary';
import { Analytic } from '../../../types/analytic';

type FormFields = {
  strategies: string[];
  categories: string[];
  showStrategies: string[];
  author: string | null;
  title: string;
  seoTitle: string;
  seoDescription: string;
  date: Date;
  anons: string;
  description: string;
  rightTitle: string | null;
  picture: string | null;
  smallPicture: string | null;
  friendlyUrl: string;
  subscription: boolean;
  [key: `zero${string}`]: boolean | undefined;
};

interface Props {
  analytic?: Analytic | null;
  onUpdate: () => void;
}

export function AnalyticForm({ analytic, onUpdate }: Props) {
  const [error, setError] = useState('');

  const { data: authorsData } = useAuthors();
  const { data: categoriesData } = useCategories();
  const strategies = useStore(StrategyDictionary$);

  const isUpdate = !!analytic;

  const authors = useMemo(() => authorsData?.result || [], [authorsData]);
  const categories = useMemo(() => categoriesData?.result || [], [categoriesData]);

  const defaultValues = useMemo(() => {
    if (!analytic) {
      return {
        strategies: [],
        categories: [],
        showStrategies: [],
        author: null,
        title: '',
        seoTitle: '',
        seoDescription: '',
        date: new Date(),
        anons: '',
        description: '',
        rightTitle: null,
        picture: null,
        smallPicture: null,
        friendlyUrl: '',
        subscription: false,
      };
    }

    const strategies = analytic?.strategies || [];

    const showStrategies = strategies
      .filter(({ showInStrategy }) => showInStrategy)
      .map(({ strategyId }) => strategyId);

    const zeroStrategies = strategies.reduce((acc, { strategyId, showInStrategy }) => {
      if (showInStrategy) {
        acc[`zero${strategyId}`] = true;
      }
      return acc;
    }, {} as Partial<FormFields>);

    return {
      title: analytic.title,
      seoTitle: analytic.seoTitle,
      seoDescription: analytic.seoDescription,
      date: new Date(analytic.date),
      anons: analytic.cutHtml,
      description: analytic.fullHtml,
      rightTitle: analytic.strategiesTitle,
      friendlyUrl: analytic.friendlyUrl,
      picture: analytic.picture,
      smallPicture: analytic.smallPicture,
      categories: analytic.categories?.map(({ id }) => id) || [],
      subscription: analytic.subscription,
      author: authors.find((item) => item.id == String(analytic.author?.id))?.id || null,
      strategies: strategies.map(({ strategyId }) => strategyId),
      showStrategies,
      ...zeroStrategies,
    };
  }, [analytic, authors]);

  const { mutateAsync, isLoading } = useUpdateAnalytic();

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<FormFields>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const getCreateData = useCallback(
    (values: FormFields) => {
      const strategies = values.strategies.map((item) => ({
        strategyId: item,
        showInStrategy: values.showStrategies.includes(item),
      }));

      const cats = categories?.filter(({ id }) => values.categories.includes(String(id)));

      return {
        title: values.title,
        seoTitle: values.seoTitle,
        seoDescription: values.seoDescription,
        date: dayjs(values.date).format('YYYY-MM-DDTHH:mm:ss'),
        cutHtml: values.anons,
        fullHtml: values.description,
        strategiesTitle: values.rightTitle,
        picture: values.picture,
        smallPicture: values.smallPicture,
        subscription: values.subscription,
        friendlyUrl: values.friendlyUrl,
        strategies,
        author: { id: Number(values.author) },
        categories: cats,
      };
    },
    [categories],
  );

  const getUpdateData = useCallback(
    (values: FormFields) => ({
      ...analytic,
      ...getCreateData(values),
    }),
    [analytic, getCreateData],
  );

  const onSubmit = async (data: FormFields) => {
    const requestData = isUpdate ? getUpdateData(data) : getCreateData(data);

    try {
      const result: { errorMessage?: string } = await mutateAsync(requestData);
      if (result.errorMessage) {
        setError(result.errorMessage);
        return;
      }
      onUpdate();
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setError(err?.message || 'An error occurred');
    }
  };

  const strategiesOptions = useMemo(
    () => strategies?.map((strategy) => ({ value: strategy.id, label: strategy.name })) || [],
    [strategies],
  );

  const authorsOptions = useMemo(
    () => authors?.map((author) => ({ value: author.id, label: author.name })) || [],
    [authors],
  );

  const categoriesOptions = useMemo(
    () => categories?.map((category) => ({ value: category.id, label: category.name })) || [],
    [categories],
  );

  const formStrategies = watch('strategies');
  const formPicture = watch('picture');
  const formSmallPicture = watch('smallPicture');
  const formShowStrategies = watch('showStrategies');

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormProvider control={control} errors={errors}>
        <ControlledTextField name="title" label="Заголовок" />
        <ControlledTextField name="rightTitle" label="Заголовок рекомендаций" />
        <ControlledTextField name="friendlyUrl" label="Url" />
        <ControlledSelectField name="strategies" multiple label="Стратегия" options={strategiesOptions} />
        {formStrategies?.length > 0 && (
          <StrategiesCheckboxGroup<FormFields>
            strategies={strategies}
            formStrategies={formStrategies}
            formShowStrategies={formShowStrategies}
            setValue={setValue}
          />
        )}
        <ControlledSelectField name="author" label="Автор" options={authorsOptions} />
        <ControlledSelectField name="categories" multiple label="Категория" options={categoriesOptions} />
        <ControlledDateTimeField name="date" label="Дата публикации" />
        {isUpdate && formPicture ? (
          <FilePath<FormFields> label="Большое изображение" name="picture" setValue={setValue} file={formPicture} />
        ) : (
          <ControlledFileField
            name="picture"
            placeholder="Большое изображение"
            url="api/admin/files/upload"
            fileTypes={['.png', '.jpg', '.jpeg', '.gif']}
            sizeView="kb"
            onRemove={() => null}
            onUpload={(fieldName, fileName) => setValue('picture', fileName)}
          />
        )}
        {isUpdate && formSmallPicture ? (
          <FilePath<FormFields>
            label="Маленькое изображение"
            name="smallPicture"
            setValue={setValue}
            file={formSmallPicture}
          />
        ) : (
          <ControlledFileField
            name="smallPicture"
            placeholder="Маленькое изображение"
            url="api/admin/files/upload"
            fileTypes={['.png', '.jpg', '.jpeg', '.gif']}
            sizeView="kb"
            onRemove={() => null}
            onUpload={(fieldName, fileName) => setValue('smallPicture', fileName)}
          />
        )}
        <ControlledTextField name="seoTitle" label="Заголовок для SEO" />
        <ControlledTextEditorField name="seoDescription" label="Описание для SEO" />
        <ControlledTextEditorField name="anons" label="Краткое описание" />
        <ControlledTextEditorField name="description" label="Полное описание" />
        <ControlledCheckboxField name="subscription" label="Подписка" />
      </FormProvider>

      {error && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isDirty || !isValid || isLoading}
        fullWidth
        sx={{ mt: 2 }}
        startIcon={isLoading && <CircularProgress size={20} />}
      >
        {isUpdate ? 'Обновить' : 'Добавить'}
      </Button>
    </form>
  );
}
