import React, { useCallback, useMemo, useState } from 'react';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { MRT_ColumnDef } from 'material-react-table';
import { useQuery } from 'react-query';

import { AnalyticDialog } from './AnalyticDialog';
import { useAuthors, useCategories } from './Form/queries';
import { RemoveAnalyticDialog } from './RemoveAnalyticDialog';
import { getAnalytics } from '../../api';
import { SortType } from '../../components/TableGrid';
import { TableGridLocal } from '../../components/TableGridLocal';
import { Analytic } from '../../types/analytic';

export const Analytics: React.FC = ({}) => {
  const [open, setOpen] = useState(false);
  const [analytic, setAnalytic] = useState<Analytic | null>(null);
  const [openDelete, setOpenDelete] = useState(false);

  useAuthors();
  useCategories();

  const { data: analytics, refetch } = useQuery('getAnalytics', () =>
    getAnalytics({
      pageNumber: 0,
      pageSize: 1000,
      sortFieldName: 'date',
      sortDirection: SortType.Desk,
    }),
  );

  const columns = useMemo<MRT_ColumnDef<Analytic>[]>(
    () => [
      {
        header: 'Заголовок',
        accessorKey: 'title',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Стратегия',
        accessorKey: 'strategyId',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Время публикации',
        accessorKey: 'date',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        Cell: ({
          cell: {
            row: {
              original: { date },
            },
          },
        }) => {
          const timeFormatted = dayjs(date).format('DD.MM.YYYY HH:mm');

          return <span>{timeFormatted}</span>;
        },
      },
      {
        header: 'Автор',
        accessorKey: 'authorId',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        Cell: ({
          cell: {
            row: {
              original: { author },
            },
          },
        }) => {
          const name = author?.displayName ?? '';

          return <span>{name}</span>;
        },
      },
      {
        header: 'Статус',
        accessorKey: 'active',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        Cell: ({
          cell: {
            row: {
              original: { active },
            },
          },
        }) => {
          const name = active ? 'Активный' : 'Неактивный';

          return <span>{name}</span>;
        },
      },
      {
        header: '',
        accessorKey: 'id',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        size: 40,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <EditNoteRoundedIcon
              sx={{ cursor: 'pointer', marginTop: 0.5 }}
              onClick={() => {
                setOpen(true);
                setAnalytic(original);
              }}
            />
          );
        },
      },
      {
        header: '',
        accessorKey: 'remove',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        size: 40,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <SwapHorizRoundedIcon
              sx={{ cursor: 'pointer', marginTop: 0.5 }}
              className="fall"
              onClick={() => {
                setOpenDelete(true);
                setAnalytic(original);
              }}
            />
          );
        },
      },
    ],
    [],
  );

  const handleUpdate = useCallback(() => {
    setOpen(false);
    setOpenDelete(false);
    setAnalytic(null);
    refetch();
  }, [refetch]);

  const handleCloseDialog = useCallback(() => {
    setOpen(false);
    setOpenDelete(false);
    setAnalytic(null);
  }, []);

  return (
    <>
      <Button variant="contained" color="primary" startIcon={<AddRoundedIcon />} onClick={() => setOpen(true)}>
        Добавить
      </Button>
      {open && <AnalyticDialog analytic={analytic} onClose={handleCloseDialog} onUpdate={handleUpdate} />}
      {openDelete && analytic && (
        <RemoveAnalyticDialog analytic={analytic} onClose={handleCloseDialog} onUpdate={handleUpdate} />
      )}
      <div style={{ marginTop: '30px' }}>
        <TableGridLocal
          columns={columns}
          data={analytics?.result?.pageItems || []}
          pageSize={15}
          totalRowCount={analytics?.result?.total || 0}
        />
      </div>
    </>
  );
};
